import React, { useState, useCallback, useEffect } from 'react';
import axios from 'axios';

import './App.scss';

export default function App() {
  const [endpoints, setEndpoints] = useState([]);

  const fetchInfo = useCallback(async (hostname) => {
    let info;

    try {
      const source = axios.CancelToken.source();

      setTimeout(() => {
        source.cancel();
      }, 2000);

      const {data} = await axios.get(`https://${hostname}/info`, {
        cancelToken: source.token,
      });

      info = {
        pop: data.pop,
        rtt: data.rtt,
      };
    } catch (err) {
      console.log(err);
    }

    return info;
  }, []);

  const beautify = useCallback((_endpoints) => {
    let endpoints = [..._endpoints];

    endpoints.sort((a, b) => {
      if ((a.error || a.rtt) && !(b.error || b.rtt)) {
        return -1;
      } else if ((b.error || b.rtt) && !(a.error || a.rtt)) {
        return 1;
      }

      if (a.error && !b.error) {
        return 1;
      } else if (b.error && !a.error) {
        return -1;
      }

      if (a.rtt && !b.rtt) {
        return -1;
      } else if (b.rtt && !a.rtt) {
        return 1;
      }

      return a.rtt - b.rtt;
    });

    let longestServerLabelLength = 0;

    for (const [i, endpoint] of endpoints.entries()) {
      endpoints[i].serverLabel = endpoint.pop;

      if (endpoint.ipVersion === 6) {
        endpoints[i].serverLabel += ` (IPv${endpoint.ipVersion})`;
      }

      longestServerLabelLength = Math.max(longestServerLabelLength, endpoints[i].serverLabel.length);
    }

    for (const [i, endpoint] of endpoints.entries()) {
      endpoints[i].serverLabel = endpoint.serverLabel + '\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0'.slice(0, longestServerLabelLength - endpoint.serverLabel.length);
    }

    for (const [i, endpoint] of endpoints.entries()) {
      if (endpoint.error) {
        endpoints[i].resultLabel = ' error';
      } else if (endpoint.rtt) {
        endpoints[i].resultLabel = Math.round(endpoint.rtt/1000) + ' ms';
        endpoints[i].resultLabel = '\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0'.slice(0, 6 - endpoints[i].resultLabel.length) + endpoints[i].resultLabel;
      }
    }

    return endpoints;
  }, []);

  const testIPv6 = useCallback(async () => {
    try {
      await axios.get('https://test-ipv6.nextdns.io', {
        timeout: 2000,
      });
      return true;
    } catch {
      return false;
    }
  }, []);

  const getStatus = useCallback(async () => {
    const randomStr = Math.random().toString(36).slice(2, 15);

    const {data} = await axios.get(`https://${randomStr}.test.nextdns.io`);

    const status = {};

    if (data.status !== 'unconfigured') {
      status.method = data.anycast ? 'anycast' : 'ultralow';
      status.pop = data.server.split('-').slice(0, 2).join('-');
      status.ipVersion = data.client.includes(':') ? 6 : 4;
    }

    return status;
  }, []);

  const run = useCallback(async () => {
    const {data: pops} = await axios.get('https://router.nextdns.io/?source=ping');

    const supportsIPv6 = await testIPv6();

    const status = await getStatus();

    const endpoints = [];

    for (const pop of pops) {
      for (const ipVersion of ['ipv4', 'ipv6']) {
        if (!pop[ipVersion]) {
          continue;
        }

        if (ipVersion === 'ipv6' && !supportsIPv6) {
          continue;
        }

        const endpoint = {
          pop: pop.pop,
          ipVersion: ipVersion === 'ipv6' ? 6 : 4,
          hostname: `${ipVersion}-${pop.server}.edge.nextdns.io`,
          routes: [],
          candidate: false,
          current: false,
        };

        endpoints.push(endpoint);
      }
    }

    setEndpoints(endpoints);

    for (const method of ['anycast', 'ultralow']) {
      for (const pool of [1, 2]) {
        for (const ipVersion of [4, 6]) {
          if (ipVersion === 6 && !supportsIPv6) {
            continue;
          }

          const route = method + pool;

          let hostname;
          let defaultLabel;

          if (method === 'anycast') {
            hostname = `ipv${ipVersion}-anycast.dns${pool}.nextdns.io`;
            defaultLabel = `anycast.dns${pool}.nextdns.io`;
          } else {
            hostname = `ipv${ipVersion}.dns${pool}.nextdns.io`;
            defaultLabel = `dns${pool}.nextdns.io`;
          }

          let candidate = false;

          if (method === status.method) {
            candidate = true;
          }

          const info = await fetchInfo(hostname);

          setEndpoints(endpoints => {
            let _endpoints = [...endpoints];

            let current = false;

            if (info) {

              if (info.pop === status.pop && ipVersion === status.ipVersion) {
                current = true;
              }

              for (const [i, endpoint] of _endpoints.entries()) {
                if (info.pop === endpoint.pop && ipVersion === endpoint.ipVersion) {
                  if (!endpoint.routes.includes(route)) {
                    _endpoints[i].routes.push(route);
                  }

                  _endpoints[i].candidate = endpoint.candidate || candidate;

                  _endpoints[i].current = endpoint.current || current;

                  _endpoints[i].rtt = info.rtt;

                  return _endpoints;
                }
              }
            }

            const endpoint = {
              pop: info ? info.pop : defaultLabel,
              ipVersion: ipVersion,
              hostname: hostname,
              routes: [route],
              candidate: candidate,
              current: current,
              rtt: info ? info.rtt : undefined,
              error: !info ? true : undefined,
            };

            _endpoints.push(endpoint);

            return _endpoints;
          });
        }
      }
    }

    for (const endpoint of endpoints) {
      if (endpoint.rtt || endpoint.error) {
        continue;
      }

      const info = await fetchInfo(endpoint.hostname);

      setEndpoints(endpoints => endpoints.map(_endpoint => {
        if (_endpoint.pop === endpoint.pop && _endpoint.ipVersion === endpoint.ipVersion) {
          if (!info) {
            endpoint.error = true;
          } else {
            endpoint.rtt = info.rtt;
          }
        }

        return _endpoint;
      }));
    }
  }, [fetchInfo, testIPv6, getStatus]);

  useEffect(() => {
    run();
  }, [run]);

  return (
    <div>
      {beautify(endpoints).map(endpoint =>
        <div key={`${endpoint.pop}-${endpoint.ipVersion}`} style={{opacity: endpoint.rtt || endpoint.error ? 1 : 0.3}}>
          <span>{endpoint.current ? '■' : '\xa0'}</span>

          <span>&nbsp;</span>

          <span style={{fontWeight: endpoint.candidate ? 600 : 'normal'}}>
            {endpoint.serverLabel}
          </span>

          <span>&nbsp;&nbsp;</span>

          {!!endpoint.resultLabel &&
            <span style={{fontWeight: endpoint.candidate ? 600 : 'normal'}}>{endpoint.resultLabel}</span>
          }

          {endpoint.routes.length > 0 &&
            <>
              <span>&nbsp;&nbsp;</span>
              <span style={{opacity: 0.35}}>({endpoint.routes.join(', ')})</span>
            </>
          }
        </div>
      )}
    </div>
  );
}
